import { graphql, useStaticQuery } from "gatsby"
import React, { useContext } from "react"
import { Helmet } from "react-helmet"

import { PageContext } from "../Context/PageContextProvider"
import Status from "../Status"

const Layout = ({ children }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  const { pageType } = useContext(PageContext)

  const bodyClasses =
    pageType === "dark"
      ? "bg-primary font-sans leading-normal tracking-normal"
      : "bg-white font-sans leading-normal tracking-normal"
  return (
    <div>
      <Helmet
        title={site?.siteMetadata?.title || ""}
        bodyAttributes={{
          class: bodyClasses,
        }}
      />
      <Status className="absolute top-0" />
      <main className="container mx-auto w-full">{children}</main>
    </div>
  )
}

export default Layout
