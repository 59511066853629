import { Link, navigate } from "gatsby"
import React, { useContext } from "react"
import { useCart } from "react-use-cart"

import { useMatch } from "@reach/router"

import { isLoggedIn, logout } from "../../utils/auth"
import { PageContext } from "../Context/PageContextProvider"
import CartIcon from "./../../icons/cart-shopping-regular.svg"
import ChevronIcon from "./../../icons/circle-chevron-left-solid-blue.svg"
import PlusIcon from "./../../icons/circle-plus-solid.svg"
import LogoNeg from "./../../img/logo-neg.png"
import LogoPos from "./../../img/logo-pos.png"

const Status = () => {
  const { pageType } = useContext(PageContext)
  const { items, emptyCart } = useCart()
  const isCartPage = useMatch("/app/cart")
  const isCustomerSelectionPage = useMatch("/app/customer-selection")

  if (!isLoggedIn()) {
    return <></>
  }

  return (
    <>
      <header className="absolute top-0 flex justify-center items-center h-45 w-full z-20">
        <Link
          onClick={() => navigate(-1)}
          className={`absolute flex items-center justify-center font-bold left-10 sm:left-10 top-10 ${
            pageType === "dark" ? "text-white" : "text-primary"
          }`}
          to=""
        >
          <span className="koff-icon-fill-white-30 flex items-center">
            <img
              src={ChevronIcon}
              width="44"
              className="mr-6 inline-block z-10"
            />{" "}
            Takaisin
          </span>
        </Link>
        <Link
          to=""
          onClick={(event) => {
            event.preventDefault()
            navigate("/app/customer-selection")
          }}
        >
          <img
            src={pageType === "dark" ? LogoNeg : LogoPos}
            alt="Sinebrychoff logo"
            className="block mx-auto"
            width="120"
          />
        </Link>
        {isCustomerSelectionPage && (
          <Link
            to="/"
            className="absolute flex items-center justify-center py-3 px-8 top-16 right-1 sm:right-4 sm:top-16 sm:mr-60 rounded-full bg-koff-blue text-white text-xs"
            onClick={(event) => {
              event.preventDefault()
              logout(() => navigate(`/app/login`))
            }}
          >
            Kirjaudu ulos
          </Link>
        )}
        {items.length > 0 && isCartPage && (
          <Link
            className="absolute flex items-center justify-center py-1 pl-8 pr-2 top-16 right-1 sm:right-4 sm:top-[25px] sm:mr-60 rounded-full bg-koff-blue text-white text-xs"
            to=""
            onClick={() => emptyCart()}
          >
            Tyhjennä valikoima{" "}
            <div className="inline-block self-center ml-2">
              <span className="koff-icon-fill-white-24">
                <img src={PlusIcon} width="24" className="block rotate-45" />
              </span>
            </div>
          </Link>
        )}
        <Link
          className="absolute flex items-center justify-center py-6 px-6 font-bold right-10 top-10 rounded-full bg-koff-blue"
          to="/app/cart"
        >
          <img src={CartIcon} width="28" className="block" />
          {items.length > 0 && (
            <span className="absolute -top-5 -right-5 font-bold text-white bg-koff-green flex items-center justify-center w-[20px] h-[20px] text-xs rounded-full">
              {items.length}
            </span>
          )}
        </Link>
      </header>
    </>
  )
}

export default Status
