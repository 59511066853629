import axios from "axios"
import { navigate } from "gatsby"

import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"

export const isBrowser = typeof window !== `undefined`
export const apiURL = process.env.GATSBY_STRAPI_API_URL

const getUser = () => {
  return window.localStorage.gatsbyUser
    ? JSON.parse(window.localStorage.gatsbyUser)
    : {}
}

export const setUser = (user) => {
  window.localStorage.gatsbyUser = JSON.stringify(user)
}

export const isLoggedIn = () => {
  if (!isBrowser) return false

  const user = getUser()

  return !!user.jwt
}

export const getCurrentUser = () => {
  const user = getUser()
  if (isBrowser && user?.user) {
    return user.user
  }

  return {}
}

export const getToken = () => {
  return getUser()?.jwt || null
}

export const axiosInstance = () => {
  const token = getUser().jwt

  return axios.create({
    baseURL: `${apiURL}/api`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const apolloClient = () => {
  const httpLink = createHttpLink({
    uri: `${apiURL}/graphql`,
  })

  const authLink = setContext((_, { headers }) => {
    const token = getToken()
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError && isBrowser) {
      if (networkError.statusCode === 401) {
        navigate("/app/login")
        logout(() => {})
      } else {
        navigate("/app/offline")
      }
    }
  })

  return new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  })
}

export const logout = (callback) => {
  if (!isBrowser) return

  setUser({})
  callback()
}
